import React from 'react';
import './CustomLinkButton.scss';

export const CustomLinkButton = ({ name, url }) => {
    return (
        <a className="link-button" href={url} target='_blank' rel="noopener noreferrer">
            {name}
        </a>
    )
}
