import { createContext, useState, useEffect } from "react";

export const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {
    const themeKeyForLocalStorage = "theme-shubhakanta-portfolio";
    const setInitialTheme = () => {
        const storedTheme = localStorage.getItem(themeKeyForLocalStorage);

        const prefersDark =
            window.matchMedia &&
            window.matchMedia("(prefers-color-scheme: dark)").matches;

        const defaultDark =
            storedTheme === "dark" || (storedTheme === null && prefersDark);

        if (defaultDark) {
            return "dark";
        }
        else if (storedTheme === "light") {
            return "light";
        }
        else {
            return "dark";
        }
    }

    const [currentTheme, setcurrentTheme] = useState(setInitialTheme);

    const setDark = () => {
        document.documentElement.setAttribute("data-theme", "dark");
    };

    const setLight = () => {
        document.documentElement.setAttribute("data-theme", "light");
    };

    useEffect(() => {
        if (currentTheme === "dark") {
            localStorage.setItem(themeKeyForLocalStorage, currentTheme);
            setDark();
        }
        else if (currentTheme === "light") {
            localStorage.setItem(themeKeyForLocalStorage, currentTheme);
            setLight();
        }
        else {
            localStorage.setItem(themeKeyForLocalStorage, "dark");
            setDark();
        }
    }, [currentTheme]);


    const toggleTheme = (theme) => {
        if (theme === 'light' || theme === 'dark') {
            setcurrentTheme(theme);
        }
    };

    const value = {
        currentTheme,
        toggleTheme
    };
    return <ThemeContext.Provider value={value}>{children}</ThemeContext.Provider>;
};