import React from 'react';
import './SkillCategory.scss';

export const SkillCategory = ({ categoryName, skills }) => {
    return (
        <>
            <div className='skill-category-container'>
                <h2 className='skill-category-header'>{categoryName}</h2>
                <div className='skill-details-container'>
                    {
                        skills.map((skill, i) => (
                            <div className='skill-category-item' key={i}>
                                <img src={skill.imageUrl} alt={`${skill.name} loading...`} />
                                <div className='skill-category-name'>{skill.name}</div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </>
    );
};
