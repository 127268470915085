import React from 'react';
import './Hamburger.scss';

export const Hamburger = ({ isOpen, setIsOpen }) => {
    return (
        <>
            <div id="nav-icon" className={isOpen ? 'open' : ''} onClick={() => { setIsOpen(!isOpen) }}>
                <span></span>
                <span></span>
                <span></span>
            </div>
        </>
    );
};
