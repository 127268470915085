export const frontendSkills = [
    {
        name: "HTML",
        imageUrl: "https://res.cloudinary.com/dfkfkyedy/image/upload/v1670102649/shubhakanta-portfolio/skills/html_bgpf6c.png"
    },
    {
        name: "CSS",
        imageUrl: "https://res.cloudinary.com/dfkfkyedy/image/upload/v1670102648/shubhakanta-portfolio/skills/css_iwhmly.png"
    },
    {
        name: "Javscript",
        imageUrl: "https://res.cloudinary.com/dfkfkyedy/image/upload/v1670102649/shubhakanta-portfolio/skills/js_q068gl.png"
    },
    {
        name: "React",
        imageUrl: "https://res.cloudinary.com/dfkfkyedy/image/upload/v1670102649/shubhakanta-portfolio/skills/react_usc7z8.png"
    },
    {
        name: "Redux",
        imageUrl: "https://res.cloudinary.com/dfkfkyedy/image/upload/v1670102649/shubhakanta-portfolio/skills/redux_ctnzja.png"
    },
    {
        name: "Angular",
        imageUrl: "https://res.cloudinary.com/dfkfkyedy/image/upload/v1670102649/shubhakanta-portfolio/skills/angular_fq9oyg.png"
    },
    {
        name: "TypeScript",
        imageUrl: "https://res.cloudinary.com/dfkfkyedy/image/upload/v1670102650/shubhakanta-portfolio/skills/typescript_qzbpcd.png"
    }
];

export const backendSkills = [
    {
        name: "NodeJS",
        imageUrl: "https://res.cloudinary.com/dfkfkyedy/image/upload/v1670102649/shubhakanta-portfolio/skills/nodejs_uhgp6x.png"
    },
    {
        name: "ExpressJS",
        imageUrl: "https://res.cloudinary.com/dfkfkyedy/image/upload/v1670102648/shubhakanta-portfolio/skills/express_q8tpaq.png"
    },
    {
        name: "MongoDB",
        imageUrl: "https://res.cloudinary.com/dfkfkyedy/image/upload/v1670102649/shubhakanta-portfolio/skills/mongodb_bcr4fn.png"
    },
    {
        name: "C#",
        imageUrl: "https://res.cloudinary.com/dfkfkyedy/image/upload/v1670102648/shubhakanta-portfolio/skills/c-sharp_jrijgx.png"
    }
];

export const testingSkills = [
    {
        name: "Jest",
        imageUrl: "https://res.cloudinary.com/dfkfkyedy/image/upload/v1670102649/shubhakanta-portfolio/skills/jest_aebrwq.png"
    },
    {
        name: "Jasmine",
        imageUrl: "https://res.cloudinary.com/dfkfkyedy/image/upload/v1670102649/shubhakanta-portfolio/skills/jasmine_zquylw.png"
    },
    {
        name: "Cypress",
        imageUrl: "https://res.cloudinary.com/dfkfkyedy/image/upload/v1670102648/shubhakanta-portfolio/skills/cypress_gjml0j.png"
    }
];

export const toolsUsed = [
    {
        name: "Git",
        imageUrl: "https://res.cloudinary.com/dfkfkyedy/image/upload/v1670102648/shubhakanta-portfolio/skills/git_qikx4e.png"
    },
    {
        name: "TFS",
        imageUrl: "https://res.cloudinary.com/dfkfkyedy/image/upload/v1670102649/shubhakanta-portfolio/skills/tfs_mk45ms.png"
    },
    {
        name: "Jenkins",
        imageUrl: "https://res.cloudinary.com/dfkfkyedy/image/upload/v1670102649/shubhakanta-portfolio/skills/jenkins_xi3ebd.png"
    }
];
