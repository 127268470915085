import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import emailjs from '@emailjs/browser';
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { CustomSpinner } from '../CustomSpinner/CustomSpinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { githubLink, linkedinLink, emailAddress } from '../../data/personal-data';
import { emailIconLink, gitHubIconLink, linkedInIconLink } from '../../data/social-icons-data';
import './Contact.scss';

export const Contact = () => {
    const [isSendingEmail, setIsSendingEmail] = useState(false);
    const formInitialValues = {
        name: '',
        email: '',
        subject: '',
        message: ''
    }
    const formik = useFormik({
        initialValues: formInitialValues,
        validationSchema: Yup.object({
            name: Yup.string().required(),
            email: Yup.string().email().required(),
            subject: Yup.string().required(),
            message: Yup.string().required()
        }),
        onSubmit: values => {
            const templateParams = {
                name: values.name,
                email: values.email,
                subject: values.subject,
                message: values.message,
            }
            setIsSendingEmail(true);
            emailjs
                .send('service_lur7nyo', 'template_r5olghh', templateParams, 'vRJ6BA4BO5rfoKhNt')
                .then(() => {
                    setIsSendingEmail(false);
                    formik.resetForm();
                    toast.success("Message successfully sent !", {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        autoClose: 5000
                    });
                })
                .catch(() => {
                    setIsSendingEmail(false);
                    toast.error("Failed to send the message, please try again !", {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        autoClose: 5000
                    });
                })
                .finally(() => {
                    setIsSendingEmail(false);
                });
        },
    });
    const showInValidToast = () => {
        if (!formik.dirty || !formik.isValid) {
            toast.info("Please fill all the required fields !", {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 5000
            });
        }
    }
    return (
        <>
            <div className='contact-container'>
                <h2 className='contact-header'>
                    <div>
                        Contact Me
                    </div>
                </h2>
                <div className='contact-content-container'>
                    <div className='contact-form-container'>
                        <form autoComplete='off' onSubmit={formik.handleSubmit}>
                            <div className='contact-form-name-email-container'>
                                <div>
                                    <input type='text' id='name' name='name' placeholder='Name'
                                        className={formik.touched.name && formik.errors.name ? 'field-error' : ''}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.name} />
                                </div>
                                <div>
                                    <input type='email' id='email' name='email' placeholder='Email'
                                        className={formik.touched.email && formik.errors.email ? 'field-error' : ''}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.email} />
                                </div>
                            </div>
                            <div>
                                <input type='text' id='subject' name='subject' placeholder='Subject'
                                    className={formik.touched.subject && formik.errors.subject ? 'field-error' : ''}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.subject} />
                            </div>

                            <div>
                                <textarea name='message' id='message' placeholder="Please write your message here..."
                                    className={formik.touched.message && formik.errors.message ? 'field-error' : ''}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.message}>
                                </textarea>
                            </div>

                            <button type='submit' disabled={isSendingEmail} onClick={() => { showInValidToast() }}>
                                {isSendingEmail ? <CustomSpinner height='48' width='48' /> :
                                    <span>
                                        <FontAwesomeIcon icon={faPaperPlane} style={{ marginRight: '2px', fontSize: '18px' }} />
                                        SEND
                                    </span>
                                }
                            </button>
                        </form>
                    </div>
                    <div className='contact-details-container'>
                        <div>I am always up for new project ideas. I would love to hear from you. Please send me a message.<br /><br />
                            <span style={{ color: 'var(--primaryColor)', fontWeight: 'bold' }}>Email:</span><br />
                            {emailAddress}<br /><br />
                        </div>
                        <div>You can also find me here:</div>

                        <div className='contact-me-icons'>
                            <a href={linkedinLink} target='_blank' rel="noopener noreferrer">
                                <img src={linkedInIconLink} alt='linkedin is loading...' />
                            </a>
                            <a href={githubLink} target='_blank' rel="noopener noreferrer">
                                <img src={gitHubIconLink} alt='github is loading...' />
                            </a>
                            <a href={`mailto:${emailAddress}`} target='_blank' rel="noopener noreferrer">
                                <img src={emailIconLink} alt='email is loading...' />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </>
    )
}
