import React from 'react';
import Typewriter from "typewriter-effect";
import { AnimatedButton } from '../AnimatedButton/AnimatedButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { cvLink } from '../../data/personal-data';
import { shubhakantaHomepagePicLink, codingImageLink } from '../../data/personal-data';
import './Home.scss';

export const Home = () => {
    return (
        <div className="home-container">
            <div className="intro-container">
                <div className='coding-gif-container'>
                    <img src={codingImageLink} alt='coding is loading...' />
                </div>
                <div className="text-container">
                    <h1>
                        <div className="hello-field">Hello, I'm</div>
                        <div className="name-field">Shubhakanta Udgata</div>
                        <div className="interest-field">
                            <Typewriter
                                options={{
                                    strings: [`Web Developer.`, `JavaScript Enthusiast.`, `Frontend Engineer.`],
                                    autoStart: true,
                                    loop: true,
                                    pauseFor: 600
                                }}
                            />
                        </div>
                        <div className='download-cv-button-container'>
                            <AnimatedButton link={cvLink}>
                                <FontAwesomeIcon icon={faEye} />
                            </AnimatedButton>
                        </div>
                    </h1>
                </div>
            </div>
            <div className="home-image-container">
                <img src={shubhakantaHomepagePicLink} alt='Shubhakanta is loading...' />
            </div>
        </div>
    )
}
