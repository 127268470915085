import React from 'react';
import { SkillCategory } from '../SkillCategory/SkillCategory';
import './Skills.scss';
import { frontendSkills, backendSkills, testingSkills, toolsUsed } from '../../data/skills-data';

export const Skills = () => {
    return (
        <>
            <div>
                <h2 className='skills-header'>
                    <div>
                        Skills
                    </div>
                </h2>
                <div className='skills-container'>
                    <div className='skills-desc'>
                        Efficient in developing web applications using front-end technologies like
                        HTML5, CSS3, JavaScript, React, Redux, Angular etc. I have professional experience
                        in making responsive, user-friendly and easy-to-use web applicaions for the users. <br /><br />
                        I have also worked on backend  technologies like NodeJS, ExpressJS, MongoDB.
                        I am well-versed in unit testing libraries like Jest and Jasmine and e2e library like Cypress.
                        I have used source control tools like Git/ GitHub, Team Foundation Server. <br /><br />
                        I put special effort into writing clean, modular, optimized and bug-free code. I am looking forward
                        to working on some cool exciting projects that can have a positive impact.
                    </div>
                    <div className='skills-info'>
                        <SkillCategory categoryName="Frontend" skills={frontendSkills} />
                        <SkillCategory categoryName="Backend" skills={backendSkills} />
                        <SkillCategory categoryName="Testing" skills={testingSkills} />
                        <SkillCategory categoryName="Tools Used" skills={toolsUsed} />
                    </div>
                </div>
            </div>
        </>
    );
};
