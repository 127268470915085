import React from 'react';
import { ProjectItem } from '../ProjectItem/ProjectItem';
import { projects } from '../../data/project-data';
import './Projects.scss';

export const Projects = () => {
    return (
        <>
            <div className='projects'>
                <h2 className='project-header'>
                    <div>
                        Projects
                    </div>
                </h2>
                <div className='projects-container'>
                    {
                        projects.map((project, i) => (
                            <ProjectItem key={i} {...project} />
                        ))
                    }
                </div>
            </div>
        </>
    );
}
