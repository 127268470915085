import React from 'react';
import { experienceData } from '../../data/experience-data';
import './ExperienceVerticalStepper.scss';

export const ExperienceVerticalStepper = () => {
    return (
        <div>
            <ol >
                {
                    experienceData.map((experience, i) => (
                        <li className="stepper-item" key={i}>
                            <div className='stepper-content'>
                                <div className="stepper-title">{experience.position}, {experience.company}<br />( {experience.durationStart} - {experience.durationEnd} )</div>
                                <p className="stepper-desc">
                                    {experience.description}
                                </p>
                            </div>
                        </li>
                    ))
                }
            </ol>
        </div>
    )
}
