import React from 'react';
import './ProjectSkills.scss';

export const ProjectSkills = ({ skills }) => {
    return (
        <>
            <div style={{ fontSize: '22px', letterSpacing: '0.5px' }}>Tech Used</div>
            <div className='project-skills-container'>
                {
                    skills.map((skill, i) => (
                        <div className='project-skill' key={i}>
                            {skill}
                        </div>
                    ))
                }
            </div>
        </>
    )
}
