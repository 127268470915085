import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { navLinks } from '../../data/header-data';
import { Hamburger } from '../Hamburger/Hamburger';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faLinkedin, faGithub } from '@fortawesome/free-brands-svg-icons';
import { githubLink, linkedinLink, emailAddress } from '../../data/personal-data';
import { CustomSwitch } from '../CustomSwitch/CustomSwitch';
import { shubhakantaHeaderLogoLink } from '../../data/personal-data';
import './Header.scss';

export const Header = () => {
    const [isOpen, setIsOpen] = useState(false);
    return (
        <>
            <div className='header-container'>
                <div className='logo-container'>
                    <NavLink to='/'>
                        <div className='logo-image-container'>
                            <img src={shubhakantaHeaderLogoLink} alt='Shubhakanta logo is loading...' />
                        </div>
                    </NavLink>

                    <span className='logo-name'>
                        <NavLink to='/'>
                            Shubhakanta
                        </NavLink>
                    </span>

                </div>
                <div className='navlink-container'>
                    <div className='navLinks'>
                        {navLinks.map((links, i) => (
                            <NavLink to={links.url} key={i}
                                className={({ isActive }) => isActive ? 'active' : ''}>
                                {links.text}
                            </NavLink>
                        ))}
                        <div className='header-social-icons'>
                            <a href={linkedinLink} target='_blank' rel="noopener noreferrer">
                                <FontAwesomeIcon icon={faLinkedin} />
                            </a>
                            <a href={githubLink} target='_blank' rel="noopener noreferrer">
                                <FontAwesomeIcon icon={faGithub} />
                            </a>
                            <a href={`mailto:${emailAddress}`} target='_blank' rel="noopener noreferrer">
                                <FontAwesomeIcon icon={faEnvelope} />
                            </a>
                        </div>

                        <div className='dark-mode-toggle-switch'>
                            <CustomSwitch />
                        </div>
                    </div>

                </div>
                <div className='dark-mode-toggle-switch-responsive'>
                    <CustomSwitch />
                </div>

                <div className='hamburger-icon'>
                    <Hamburger isOpen={isOpen} setIsOpen={setIsOpen} />
                </div>

            </div>

            <div className={isOpen ? 'drawer drawer-open' : 'drawer'}>
                <div className='drawer-navlink-container'>
                    {navLinks.map((links, i) => (
                        <NavLink to={links.url} key={i}
                            className={({ isActive }) => isActive ? 'active' : ''}
                            onClick={() => { setIsOpen(false) }}>
                            {links.text}
                        </NavLink>
                    ))}
                    <div className='drawer-header-social-icons'>
                        <a href={linkedinLink} target='_blank' rel="noopener noreferrer">
                            <FontAwesomeIcon icon={faLinkedin} />
                        </a>
                        <a href={githubLink} target='_blank' rel="noopener noreferrer">
                            <FontAwesomeIcon icon={faGithub} />
                        </a>
                        <a href={`mailto:${emailAddress}`} target='_blank' rel="noopener noreferrer">
                            <FontAwesomeIcon icon={faEnvelope} />
                        </a>
                    </div>
                </div>
            </div>
        </>
    )
}
