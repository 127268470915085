import React from 'react';
import { NavLink } from 'react-router-dom';
import { ExperienceVerticalStepper } from '../ExperienceVerticalStepper/ExperienceVerticalStepper';
import { shubhakantaFacePicLink } from '../../data/personal-data';
import './About.scss';

export const About = () => {
    return (
        <>
            <div>
                <h2 className='about-header'>
                    <div>
                        About Me
                    </div>
                </h2>
                <div className='about-container'>
                    <div className='about-desc'>
                        <img src={shubhakantaFacePicLink} alt='Shubhakanta is loading...' />
                        Hello ! I am Shubhakanta Udgata. I am a passionate software developer living in Bengaluru, India.
                        I am currently working at Siemens Healthineers as a front end engineer. Prior to that, I
                        have worked with organizations like CGI and Tata Consultancy Services.<br /><br />
                        I have always been fascinated by computers and technology since my childhood, and I am currently living my dream
                        by working as a web developer. I am very fond of the JavaScript eco system and love to build web applications
                        using it. I am dedicated to developing elegant web apps with a delightful user experience and high performance.
                        Professionally, I have worked on a number of important projects that have added value for both the customers and
                        the organization. I am a good problem solver with a high attention to detail.<br /><br />

                        In my leisure time, I love to work out and play guitar.<br /><br />

                        I am always interested in working on cool and interesting projects with ambitious people that can help me learn and grow.
                        Please feel free to drop me a message&nbsp;
                        <span className='contact-link'>
                            <NavLink to='/contact'>
                                here.
                            </NavLink>
                        </span>

                    </div>
                    <div className='work-experience'>
                        <ExperienceVerticalStepper />
                    </div>
                </div>
            </div>
        </>
    )
}
