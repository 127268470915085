export const experienceData = [
    {
        position: "Senior Software Engineer",
        company: "Siemens Healthineers",
        durationStart: "June, 2021",
        durationEnd: "Present",
        description: "Worked on a platform team which provides various reusable UI components and APIs to the CT, MRI scanner applications. Developed an easy-to-use web platform for creating, managing and scanning the patients."
    },
    {
        position: "Software Engineer",
        company: "CGI",
        durationStart: "Oct, 2018",
        durationEnd: "June, 2021",
        description: "Developed and migrated different modules from Windows to web app in a product which provides an administrative and e-government platform for the health care of elderly and disabled people."
    },
    {
        position: "Software Engineer",
        company: "Tata Consultancy Services",
        durationStart: "June, 2016",
        durationEnd: "Oct, 2018",
        description: "Developed a web app which manages the complete workflow of the loan approval process in the banking system i.e. loan creation, assignment, reassignment and closure."
    },
];