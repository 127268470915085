export const navLinks = [
    {
        url: "/",
        text: "Home",
    },
    {
        url: "/skills",
        text: "Skills",
    },
    {
        url: "/projects",
        text: "Projects",
    },
    {
        url: "/about",
        text: "About",
    },
    {
        url: "/contact",
        text: "Contact",
    }
]