import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import './App.scss';
import { Header } from './components/Header/Header';
import { Home } from './components/Home/Home';
import { Projects } from "./components/Projects/Projects";
import { Skills } from "./components/Skills/Skills";
import { Contact } from "./components/Contact/Contact";
import { About } from './components/About/About';

function App() {
  return (
    <>
      <Router>
        <Header />
        <main className='main-content'>
          <Routes>
            <Route path='/' element={<Home />}></Route>
            <Route path='/skills' element={<Skills />}></Route>
            <Route path='/projects' element={<Projects />}></Route>
            <Route path='/about' element={<About />}></Route>
            <Route path='/contact' element={<Contact />}></Route>
            <Route path="*" element={<Navigate to="/" replace />}></Route>
          </Routes>
        </main>
      </Router>
    </>
  );
}

export default App;
