const fashionWoldDesc = `Fashion World is a fashion e-commerce website built using 
React, Redux, NodeJs, MongoDB and Stripe (for payment). Users can browse products, shop and place an order 
using this web app.`;

const digiDatadesc = `Digi Data is a file-storage platform built using Angular, Google Firebase authentication and
Firebase cloud database (NoSQL). It lets the users create folders, upload files, add folders as favorites etc.`;

const frontEndIdeDesc = `Frontend IDE is a simple online code editor build using React which allows users to write code in
HTML, CSS and JavaScript and see the results on the screen.`;

const weatherDailyDesc = `Weather Daily is a web app built using Angular and OpenWeather APIs which provides current 
location-based weather details, nearby cities weather details, weather forecast and climate condition of any city 
based on user's search.`;

export const projects = [
    {
        name: "Fashion World",
        description: fashionWoldDesc,
        imageUrl: "https://res.cloudinary.com/dfkfkyedy/image/upload/v1670102529/shubhakanta-portfolio/projects/fashion-world_xorvpf.png",
        liveLink: "https://fashion-world-store.web.app",
        github: "https://github.com/sudgata/Fashion-World",
        skillsUsed: ['React', 'Redux', 'NodeJs', 'ExpressJs', 'MongoDB', 'Stripe']
    },
    {
        name: "Digi Data",
        description: digiDatadesc,
        imageUrl: "https://res.cloudinary.com/dfkfkyedy/image/upload/v1670102527/shubhakanta-portfolio/projects/digi-data_ooy4pi.png",
        liveLink: "https://digi-data-store.web.app",
        github: "https://github.com/sudgata/Digi-Data",
        skillsUsed: ['Angular', 'Firebase', 'Firestore Database']
    },
    {
        name: "Frontend IDE",
        description: frontEndIdeDesc,
        imageUrl: "https://res.cloudinary.com/dfkfkyedy/image/upload/v1670102527/shubhakanta-portfolio/projects/frontend-ide_pnaajd.png",
        liveLink: "https://forntend-ide.web.app",
        github: "https://github.com/sudgata/frontend-ide",
        skillsUsed: ['React', 'HTML', 'CSS']
    },
    {
        name: "Weather Daily",
        description: weatherDailyDesc,
        imageUrl: "https://res.cloudinary.com/dfkfkyedy/image/upload/v1670102528/shubhakanta-portfolio/projects/weather-daily_zkoe9k.png",
        liveLink: "https://weather-daily-track.web.app",
        github: "https://github.com/sudgata/Weather-Daily",
        skillsUsed: ['Angular', 'OpenWeather API']
    },
];