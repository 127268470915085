import React from 'react'
import './AnimatedButton.scss';

export const AnimatedButton = ({ link, children }) => {
    return (
        <>
            <a href={link} className="animated-btn" rel="noopener noreferrer" target='_blank'>
                <span className="animated-btn-circle">
                    {children}
                </span>
                <span className="animated-btn-title">View My CV</span>
                <span className="animated-btn-title-hover">View My CV</span>
            </a>
        </>
    )
}
