import React from 'react';
import { CustomLinkButton } from '../CustomLinkButton/CustomLinkButton';
import { ProjectSkills } from '../ProjectSkills/ProjectSkills';
import './ProjectItem.scss';

export const ProjectItem = ({ name, description, imageUrl, liveLink, github, skillsUsed }) => {
    return (
        <>
            <div className='project-item-container'>
                <h2 className='project-name'>{name}</h2>
                <div className='project-image-container'>
                    <a href={liveLink} target='_blank' rel="noopener noreferrer">
                        <img src={imageUrl} alt={`${name} loading...`} />
                    </a>
                </div>
                <p className='project-desc'>{description}</p>
                <ProjectSkills skills={skillsUsed} />
                <div className='project-link-container'>
                    <CustomLinkButton name='Live Link' url={liveLink} />
                    <CustomLinkButton name='GitHub' url={github} />
                </div>
            </div>
        </>
    )
}
