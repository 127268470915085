import React, { useContext } from 'react';
import Switch from "react-switch";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSun, faMoon } from '@fortawesome/free-solid-svg-icons';
import { ThemeContext } from '../../contexts/ThemeContext';
import './CustomSwitch.scss';

export const CustomSwitch = () => {
    const { currentTheme, toggleTheme } = useContext(ThemeContext);
    return (
        <Switch onChange={() => {
            const newTheme = currentTheme === 'light' ? 'dark' : 'light';
            toggleTheme(newTheme);
        }}
            checked={currentTheme === 'dark'}
            checkedIcon={
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%",
                        fontSize: 19,
                        color: "white",
                        paddingLeft: 0
                    }}
                >
                    <FontAwesomeIcon icon={faMoon} />
                </div>
            }
            uncheckedIcon={
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%",
                        fontSize: 19,
                        color: "yellow",
                        paddingRight: 0
                    }}
                >
                    <FontAwesomeIcon icon={faSun} />
                </div>
            }
            onColor='#3158a0'
            offColor='#f49e00'
            height={30}
            width={58}
            handleDiameter={22.5}
        />
    )
}
